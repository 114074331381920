import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from '@src/bootstrap/store';

export {
    useContract,
    useERC20Contract,
    useDecubateContract,
} from './useContract';
export { useForceUpdate } from './useForceUpdate';
export { useIsomorphicLayoutEffect } from './useIsomporphicLayoutEffect';
export { useStakingState } from './useStakingState';
export { useNFT } from './useNFT';
export { useToken } from './useToken';
export { useTokenApproval } from './useTokenApproval';
export { useTokenBalance } from './useTokenBalance';
export { useTokenPrice } from './useTokenPrice';
export { useWeb3Onboard } from './useWeb3Onboard';

// Typed useSelector & useDipsatch hooks
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
