import { useState, useEffect } from 'react';

import { getERC20Contract } from '@src/contracts';
import { cancellablePromise } from '@src/utils/promise';
import { Contract } from '@ethersproject/contracts';

const getData = async (token: Contract) => {
    const symbol = await token.symbol();
    const decimals = await token.decimals();
    return { symbol, decimals };
};

export const useToken = (
    address: string,
): { symbol: string; decimals: number } => {
    const [symbol, setSymbol] = useState('');
    const [decimals, setDecimals] = useState(18);
    const [tried, setTried] = useState(false);
    const [prev_address, setPrevAddress] = useState(address);
    const token = getERC20Contract(address);

    useEffect(() => {
        if (!tried && token) {
            const p = getData(token);
            const { cancel, promise } = cancellablePromise(p);

            promise
                .then(
                    ({
                        symbol,
                        decimals,
                    }: {
                        symbol: string;
                        decimals: number;
                    }) => {
                        setSymbol(symbol);
                        setDecimals(decimals);
                        setTried(true);
                    },
                )
                .catch(() => setTried(true));

            return cancel;
        } else if (prev_address !== address) {
            setTried(false);
            setSymbol('');
            setDecimals(18);
        }
        setPrevAddress(address);
    }, [address, token, tried]);

    return { symbol, decimals };
};
