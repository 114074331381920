import { BigNumber } from '@ethersproject/bignumber';
import { getERC20Contract } from '@src/contracts';
import { cancellablePromise } from '@src/utils/promise';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { useERC20Contract } from '..';

export const useTokenApproval = (
    address: string,
    approver: string,
    approvee: string,
    approve_amount: string,
): {
    approved: boolean;
    loading: boolean;
    handleApprove: () => Promise<void>;
} => {
    const [approved, setHasApproval] = useState(false);
    const [loading, setLoading] = useState(false);

    const token = getERC20Contract(address);
    const token_with_signer = useERC20Contract(address, true);

    useEffect(() => {
        if (token) {
            const p = token
                .allowance(approver, approvee)
                .then((amount: BigNumber) => {
                    return amount.gte(approve_amount);
                });

            const { promise, cancel } = cancellablePromise(p);

            promise
                .then((approved: boolean) => setHasApproval(approved))
                .catch(() => true);

            return cancel;
        }
    }, [approve_amount, approver, approvee]);

    const handleApprove = async () => {
        setLoading(true);
        try {
            toast.info('Awaiting successful transaction');
            const approve_tx = await token_with_signer.approve(
                approvee,
                approve_amount,
            );
            await approve_tx.wait();
            toast.success('Successfully approved the deposit');
            setHasApproval(true);
        } catch (err) {
            toast.error(err.error?.data?.message || err.message);
        }
        setLoading(false);
    };

    return { approved, loading, handleApprove };
};
