import { useState, useEffect } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';

import { getERC721Contract } from '@src/contracts';
import { cancellablePromise } from '@src/utils/promise';

const getData = async (token: Contract) => {
    const supply = await token.totalSupply();
    const name = await token.name();

    return {
        supply,
        name,
    };
};

export const useNFT = (
    address: string,
): { name: string; total_supply: BigNumber } => {
    const [name, setName] = useState('');
    const [total_supply, setTotalSupply] = useState(BigNumber.from(0));
    const [tried, setTried] = useState(false);
    const [prev_address, setPrevAddress] = useState(address);

    const token = getERC721Contract(address);

    useEffect(() => {
        if (token && !tried) {
            const { promise, cancel } = cancellablePromise(getData(token));
            promise
                .then(
                    ({ supply, name }: { supply: BigNumber; name: string }) => {
                        setName(name);
                        setTotalSupply(supply);
                        setTried(true);
                    },
                )
                .catch(() => setTried(true));

            return cancel;
        } else if (prev_address !== address) {
            setTried(false);
            setTotalSupply(BigNumber.from(0));
            setName('');
        }
        setPrevAddress(address);
    }, [token, tried]);

    return { name, total_supply };
};
