import { useEffect } from 'react';

import { StakingPool } from '@src/ts/interfaces';
import { useAppDispatch } from '@src/hooks';
import { setPools, updatePools } from '@src/features/staking';
import { useWeb3Onboard } from '../useWeb3Onboard';

export const useStakingState = (
    pools: StakingPool[],
    management = false,
): void => {
    const dispatch = useAppDispatch();
    const { account } = useWeb3Onboard();
    // handle account change
    useEffect(() => {
        dispatch(setPools(pools));
        if (!management && !!account) {
            dispatch(updatePools(account, true, pools));
        }
    }, [pools, account]);
};
