import { useState, useEffect } from 'react';
import { BigNumber } from '@ethersproject/bignumber';

import { useForceUpdate } from '@src/hooks';
import { cancellablePromise } from '@src/utils/promise';
import { getERC20Contract } from '@src/contracts';
import { useWeb3Onboard } from '@src/hooks/useWeb3Onboard';

export const useTokenBalance = (address: string): string => {
    const [balance, setBalance] = useState('0');
    const updater = useForceUpdate(5000);
    const token = getERC20Contract(address);
    const { account } = useWeb3Onboard();

    useEffect(() => {
        if (token && account) {
            const { promise, cancel } = cancellablePromise(
                token.balanceOf(account),
            );

            promise
                .then((amount: BigNumber) => setBalance(amount.toString()))
                .catch(() => true);

            return cancel;
        }
    }, [account, token, updater]);

    return balance;
};
