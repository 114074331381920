import { commify, formatUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';

import { apollo_client } from '@src/bootstrap/apollo-client';
import { StakingPool } from '@src/ts/interfaces';
import { PROJECT } from '@src/services/project';

interface ReducedPools {
    [token_addr: string]: {
        earned_rewards: BigNumber;
        total_staked: BigNumber;
        user_stake: BigNumber;
    };
}

export const getTokenPrice = async (symbol: string): Promise<number> => {
    const {
        data: { getTokenPrice: price },
    } = (await apollo_client.query({
        query: PROJECT.GET_TOKEN_PRICE,
        variables: { symbol },
    })) as { data: { getTokenPrice: string } };

    return Number(price);
};

export const getTokenPrices = async (data: {
    [symbol: string]: unknown;
}): Promise<{ [symbol: string]: number }> => {
    return (
        await Promise.all(
            // loop through keys in data from reduced pools (each token address)
            Object.keys(data).map(async (symbol) => [
                symbol,
                await getTokenPrice(symbol),
            ]),
        )
    ).reduce(
        (acc, [sym, price]) => ({ ...acc, [sym]: price }), // reduce array to object in the format { [symbol]: price }
        {},
    );
};

export const weiToNumber = (wei: string, decimals = 18): number =>
    Number(formatUnits(wei, decimals));

export const reducePools = (pools: StakingPool[]): ReducedPools =>
    pools.reduce((totals, pool) => {
        const {
            input_token: { symbol: input_symbol },
            reward_token: { symbol: reward_symbol },
            claimed_reward = 0,
            earned_reward = 0,
            user_stake = 0,
            total_staked = 0,
        } = pool;
        // if input token object exists
        if (totals[input_symbol]) {
            // add to total staked and user stake
            totals[input_symbol] = {
                ...totals[input_symbol],
                total_staked:
                    totals[input_symbol].total_staked.add(total_staked),
                user_stake: totals[input_symbol].user_stake.add(user_stake),
            };
        } else {
            // otherwise initialise object with values
            totals[input_symbol] = {
                earned_rewards: BigNumber.from(0),
                total_staked: BigNumber.from(total_staked),
                user_stake: BigNumber.from(user_stake),
            };
        }

        // if reward token exists
        if (totals[reward_symbol]) {
            // add to earned rewards
            totals[reward_symbol] = {
                ...totals[reward_symbol],
                earned_rewards: totals[reward_symbol].earned_rewards
                    .add(claimed_reward)
                    .add(earned_reward),
            };
        } else {
            // otherwise initialise object with values
            totals[reward_symbol] = {
                earned_rewards:
                    BigNumber.from(claimed_reward).add(earned_reward),
                total_staked: BigNumber.from(0),
                user_stake: BigNumber.from(0),
            };
        }

        return totals;
    }, {});

export const aggregateValues = (
    key: string,
    pools: {
        [key: string]: {
            earned_rewards: BigNumber;
            total_staked: BigNumber;
            user_stake: BigNumber;
        };
    },
    prices: Record<string, number>,
    token_decimals: Record<string, number>,
): string => {
    return `~ ${commify(
        Object.keys(pools)
            .map((addr) => {
                const total = weiToNumber(
                    pools[addr][key].toString(),
                    token_decimals[addr],
                );
                const price = prices[addr] || 0;
                return total * price;
            })
            .reduce((total, current) => total + current, 0)
            .toFixed(2),
    )} USD`;
};
