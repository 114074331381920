import { useState, useEffect } from 'react';
import { BigNumber } from '@ethersproject/bignumber';

import { getTokenPrice } from '@src/components/Staking/Analytics/util';
import { parseWei } from '@src/utils/web3';
import { DEFAULT_TOKEN } from '@src/config';
import { cancellablePromise } from '@src/utils/promise';

const { symbol: default_symbol } = DEFAULT_TOKEN;

export const useTokenPrice = (): BigNumber => {
    const [token_price, setTokenPrice] = useState(BigNumber.from(0));
    useEffect(() => {
        const p = getTokenPrice(default_symbol);
        const { cancel, promise } = cancellablePromise(p);

        promise
            .then((price: number) => setTokenPrice(parseWei(price.toString())))
            .catch(() => true);

        return cancel;
    }, []);

    return token_price;
};
